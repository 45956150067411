
.hr-divider[data-v-4c02946e]{display:block;position:relative;outline:0;border:0;text-align:center;height:1.5em;margin:1.5em 0
}
.hr-divider[data-v-4c02946e]:before{content:"";background:rgba(0,0,0,.1);position:absolute;left:0;top:50%;width:100%;height:1px
}
.hr-divider[data-v-4c02946e]:after{content:attr(data-content);position:relative;display:inline-block;padding:0 3em;line-height:150%;font-size:15px;color:#303444;background-color:#f5f5f5
}
@media(max-width: 1100px){
.hr-divider[data-v-4c02946e]{margin:1em 0
}
}
@media(max-width: 960px){
.hr-divider[data-v-4c02946e]{margin:.5em 0
}
}