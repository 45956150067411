
section#connect .form-section-inner[data-v-6437b456],section#connect-acceptinvite .form-section-inner[data-v-6437b456]{margin-right:3.75em
}
section#connect .connect-option[data-v-6437b456],section#connect-acceptinvite .connect-option[data-v-6437b456]{padding:1.2em;margin:.5em 0;font-size:15px;display:flex;flex-flow:column nowrap;justify-content:flex-start;align-items:center;background:#ececec;border-radius:5px;cursor:pointer;transition:background .3s
}
section#connect .connect-option .option-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon[data-v-6437b456]{height:60px;width:60px;border-radius:144px;border:2px solid rgba(0,0,0,.15);display:flex;flex-flow:column;justify-content:center;align-items:center
}
section#connect .connect-option .option-icon img.login-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon img.login-icon[data-v-6437b456]{display:inline-block;max-width:36px;max-height:36px
}
section#connect .connect-option .option-text[data-v-6437b456],section#connect-acceptinvite .connect-option .option-text[data-v-6437b456]{padding:.8em 0 0 0;font-family:"Exo","Helvetica Neue",Helvetica,Arial,sans-serif;text-transform:uppercase;color:#5e5e5e;font-weight:600;text-align:center
}
section#connect .connect-option[data-v-6437b456]:active,section#connect-acceptinvite .connect-option[data-v-6437b456]:active{background:#e0e0e0
}
@media(max-width: 1280px){
section#connect .form-section-inner[data-v-6437b456],section#connect-acceptinvite .form-section-inner[data-v-6437b456]{margin-left:1em;margin-right:2em
}
section#connect .connect-option[data-v-6437b456],section#connect-acceptinvite .connect-option[data-v-6437b456]{padding:1em
}
section#connect .connect-option .option-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon[data-v-6437b456]{height:48px;width:48px
}
section#connect .connect-option .option-icon img.login-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon img.login-icon[data-v-6437b456]{display:inline-block;max-width:24px;max-height:24px
}
section#connect .connect-option .option-text[data-v-6437b456],section#connect-acceptinvite .connect-option .option-text[data-v-6437b456]{padding:.5em 0 0 0
}
}
@media(max-width: 1100px){
section#connect .form-section-inner[data-v-6437b456],section#connect-acceptinvite .form-section-inner[data-v-6437b456]{margin-left:.5em;margin-right:1em
}
section#connect .connect-option[data-v-6437b456],section#connect-acceptinvite .connect-option[data-v-6437b456]{font-size:16px
}
}
@media(max-width: 960px){
section#connect .form-section-inner[data-v-6437b456],section#connect-acceptinvite .form-section-inner[data-v-6437b456]{margin:0
}
section#connect .connect-option[data-v-6437b456],section#connect-acceptinvite .connect-option[data-v-6437b456]{flex-flow:row;justify-content:center
}
section#connect .connect-option .option-text[data-v-6437b456],section#connect-acceptinvite .connect-option .option-text[data-v-6437b456]{padding:0 0 0 1em
}
}
@media(max-width: 768px){
section#connect .connect-option .option-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon[data-v-6437b456]{height:32px;width:32px
}
section#connect .connect-option .option-icon img.login-icon[data-v-6437b456],section#connect-acceptinvite .connect-option .option-icon img.login-icon[data-v-6437b456]{display:inline-block;max-width:16px;max-height:16px
}
}